import styled from 'styled-components/macro'
import { Button } from '../../../commons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
`

export const Product = styled.div``

export const ProductWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 4.3rem;
  flex-wrap: wrap;
  gap: 7.2rem;
  @media ${({ theme }) => theme.screens.md} {
    justify-content: center;
    gap: 3rem 7.2rem;
  }
`

export const ProductDetailMobile = styled.div`
  display: none;
  width: 100%;
  @media ${({ theme }) => theme.screens.sm} {
    display: block;
  }
`
export const ProductDetailDesk = styled.div`
  @media ${({ theme }) => theme.screens.sm} {
    display: none;
  }
`
export const ProductGallery = styled.div`
  max-width: 47.5rem;
  width: 100%;
  /* margin-right: 7.2rem; */
`

export const ProductContent = styled.div`
  flex-grow: 1;
  flex: 1;
  min-width: 280px;
`

export const ProductCode = styled.div`
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const ProductTitle = styled.h1`
  margin-top: 1rem;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -0.1rem;
  color: ${({ theme }) => theme.colors.orange.default};
`
export const ProductSubTitle = styled.small`
  margin-top: 1rem;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: -0.1rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const ProductInfos = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  flex-wrap:wrap;
`

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-right: 2.5rem;

    &::after {
      content: "";
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.orange.default};
      margin-left: 2.5rem;
    }
  }
`

export const ProductInfoLabel = styled.span`
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const ProductInfoValue = styled(ProductInfoLabel)`
  margin-left: 0.7rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const ProductCartContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 0.15rem solid ${({ theme }) => theme.colors.mono.greyLight};
  @media ${({ theme }) => theme.screens.md} {
    justify-content: center;
    * {
      text-align: center;
    }
  }
`

export const ProductPrices = styled.div`
  @media ${({ theme }) => theme.screens.md} {
    padding-bottom: 4rem;
    /* Mono/Grey Light */

    border-bottom: 1.5px solid ${({ theme }) => theme.colors.mono.greyLight};
    margin-bottom: 4rem;
  }
`

export const ProductPriceReduce = styled.div`
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};

  span {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
    letter-spacing: -0.03rem;
    text-decoration-line: line-through;
  }
`

export const ProductPriceContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const ProductPriceLabel = styled.span`
  margin-right: 1rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -0.03rem;
`

export const ProductPrice = styled.span`
  margin-right: 1rem;
  font-size: 4.8rem;
  line-height: 5rem;
  letter-spacing: -0.2rem;
`

export const ProductOriginalPrice = styled.span`
  font-weight: 700;
  font-size: 1.8rem;
  letter-spacing: -0.1rem;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const ProductPriceDiscount = styled.span`
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  background: ${({ theme }) => theme.colors.green.light10};
  border-radius: 10rem;
`

export const ProductCartInfos = styled.div`
  max-width: 33.8rem;
  width: 100%;
  max-height: 15.2rem;
`

export const ProductButton = styled(Button)`
  margin-left: 1rem;
  width: calc(100% - 8rem);

  @media ${({ theme }) => theme.screens.md} {
    margin-right: 1rem;
    margin-top: 1rem;
  }
`

export const ProductDescriptionContainer = styled.div`
  margin: 4rem 0;
  padding-top: 4rem;
  border-top: 0.15rem solid ${({ theme }) => theme.colors.mono.greyLight};
`

export const ProductDescription = styled.div`
  max-width: 72.8rem;
  width: 100%;
  line-height: 3.5rem;

  & > *:not(:last-child) {
    margin-bottom: 3.1rem;
  }

  ul {
    list-style: disc inside;
  }
`

export const ProductCartField = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

export const ProductCartFieldLabel = styled.div`
  width: 7.1rem;
  margin-right: 1rem;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.7rem;
  text-align: right;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const ProductCartFieldWrapper = styled.div`
  flex-grow: 1;
`
export const ProductKitContentLabel = styled.div`
font-weight: 400;
font-size: 1.7rem;
line-height: 1.7rem;
margin: 1.5rem 1rem 1rem 2rem;
color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const ProductStructureTitle = styled.h1`
  font-weight: 700;
  font-size: 2.7rem;
  line-height: 3.8rem;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.colors.mono.black};

  span {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`

export const ProductStructureSubItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-left: 1rem;
  font-weight: 300;
  font-size: 1.6rem;
`

export const ProductStructureSubItemContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  gap: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.mono.greyLight};
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.mono.white};
  max-width: 80rem;
  width: 100%;
`

export const ProductThumbnail = styled.img`
  width: 10rem;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-right: 1rem;
`;

export const ProductStructureInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ProductStructureName = styled.h2`
  font-size: 1.6rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.mono.black};
  margin-bottom: 0rem;
`;

export const ProductStructureDescription = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`;

export const ProductStructurePrice = styled.div`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
  margin-bottom: 1rem;
`;

export const ProductStructureButton = styled(Button)`
  margin-left: auto;
  display: flex;
  align-items: end;
  justify-content: center;
  width: auto;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  padding: 0.5rem 1rem;
`;