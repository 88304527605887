import styled, { css } from 'styled-components/macro'
import { Button } from '../../commons/Button'

export const GradeSelectorContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(auto, auto);
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  border-radius: 6px;
  width: auto;
  padding: 1rem;
  gap: 1.5rem;
  max-height: 42rem;
  overflow: auto;

  @media ${({ theme }) => theme.screens.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Custom Scrollbar */
  &::-webkit-scrollbar {
    width: 6px;  /* Largura da barra de rolagem */
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.mono.grey}; /* Cor do "thumb" */
    border-radius: 3px; /* Bordas arredondadas */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.mono.grey}; /* Cor ao passar o mouse */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Fundo da barra */
  }
`

export const GradeSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  border-radius: 6px;
  gap: 0.5rem;
  cursor: pointer;

  &:hover{
    border: 1px solid ${({ theme }) => theme.colors.orange.default};
  }

  &.highlighted {
    border: 1px solid ${({ theme }) => theme.colors.orange.default};
  }
`

export const GradeTitleWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.mono.greyLight};
  width: 100%;
  padding: 0.5rem;
  border-radius: 6px 6px 0 0;
`

export const GradeTitle = styled.p`
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.mono.black};
`

export const GradePrice = styled.span`
  color: ${({ theme }) => theme.colors.orange.default};
`

export const IncrementStepperWrapper = styled.div`
  display: flex;
  alignItems: center;
  height: 100%;
`

export const GradeBuyButton = styled(Button)`
  ${({ model }) => css`
    position: relative;
    z-index: 100;
    display: block;
    width: 200px;
    margin-left: auto;
    margin-top: 2rem;
    flex: 1;

    @media ${({ theme }) => theme.screens.md} {
      width: ${model === 'shadow' ? 'auto' : '100%'};
      margin: ${model === 'shadow' ? '0 auto' : 'initial'};
      padding: ${model === 'shadow' ? '1.5rem 2rem' : '1.5rem 1rem'};
    }
    @media ${({ theme }) => theme.screens.xs} {
      padding: 1rem 0.5rem;
      font-size: 1.4rem
    }
  `}
`