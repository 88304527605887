import * as S from './styles'
import React, { useState, useEffect, useRef } from 'react'
import { useCart } from '../../context/CartContext'
import formatMoney from '../../util/formatMoney'
import IncrementStepper from '../IncrementStepper'

const GradeSelector = ({ grade, productId, onSelectGrade }) => {
  const { addProduct, cart } = useCart()
  const [allGradeQty, setAllGradeQty] = useState();
  const [selectedVariationView, setSelectedVariationView] = useState();
  const prevQtyRef = useRef({});

  const productOnCart = cart?.items?.filter(item => item.productId === productId)
  const cartQuantities = productOnCart?.reduce((acc, item) => {
    // pra cada variação da grade, reduz a um obj com id na propriedade e quantidade no valor. ex: {8765: 2}
    acc[item.productSkuDataId] = item.quantity;
    return acc;
  }, {});

  const updateAllGradeQty = () =>
    // pra cada variação da grade, cria um obj com id e quantidade. ex: {id: 8765, quantity: 2}
    grade.map((item) => ({
      id: item.id,
      quantity: cartQuantities?.[item.id] || 0,
    }))

  const handleQuantityChange = (id, newQty) => {
    // atualiza somente a quantidade da variação atual
    setAllGradeQty((prev) =>
      prev.map((item) => (item.id === id ? { ...item, quantity: newQty } : item))
    );
  };

  const handleMouseLeave = (gradeId) => {
    const currentQty = allGradeQty.find((item) => item.id === gradeId)?.quantity || 0;
    const prevQty = prevQtyRef.current[gradeId] || 0;

    if (gradeId && currentQty >= 1 && currentQty !== prevQty) {
      addProduct(productId, currentQty, gradeId);
      prevQtyRef.current[gradeId] = currentQty; // Atualiza valor anterior
    };
  };

  useEffect(() => {
    setAllGradeQty(updateAllGradeQty());
    grade.forEach((item) => {
      prevQtyRef.current[item.id] = cartQuantities?.[item.id] || 0;
    });
  }, [cart]);

  return (
    <>
      <S.GradeSelectorContainer>
        {grade.map(item => (
          <S.GradeSelector key={item.id} 
            onMouseLeave={() => handleMouseLeave(item.id)}
            onClick={() => {
              setSelectedVariationView(item.id);
              onSelectGrade(item.id); // Atualiza o estado do componente pai (Detalhes do produto)
            }}
            className={item.id === selectedVariationView ? "highlighted" : ""}
          >
            <S.GradeTitleWrapper>
              <S.GradeTitle>{item.skuDescription}</S.GradeTitle>
            </S.GradeTitleWrapper>
            <S.GradePrice>{formatMoney(item.price)}</S.GradePrice>
            <S.IncrementStepperWrapper>
              <IncrementStepper
                qty={allGradeQty?.find((q) => q.id === item.id)?.quantity || 0}
                setQty={(newQty) => handleQuantityChange(item.id, newQty)}
                gradeId={item.id}
                productId={item.productId}
              />
            </S.IncrementStepperWrapper>
          </S.GradeSelector>
        ))}
      </S.GradeSelectorContainer>
    </>
  )
}

export default GradeSelector