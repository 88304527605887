import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import Icon from '../../commons/Icon'
import { Button } from '../../commons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const HozizontalMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.colors.orange.default};

  @media ${({ theme }) => theme.screens.lg} {
    display: none;
  }
`

export const HorizontalMenuNav = styled.nav`
  padding: 0 4rem;
`

export const HorizontalMenuList = styled.ul`
  display: flex;
`

export const HorizontalMenuItem = styled.li`
  max-width: 24rem;
  text-align: center;
`

export const HorizontalMenuItemLinkLabel = styled.span`
  flex-grow: 1;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  color: ${({ theme, $hovered }) => $hovered === true ? theme.colors.orange.default : theme.colors.mono.white};
  text-transform: uppercase;
  transition: color 0.3s;
`

export const HorizontalMenuItemLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 1.5rem 2rem;
  
  ${({ theme, $hovered }) => $hovered === true ? `background-color: ${theme.colors.mono.white};` : ''};
  ${({ $customHeaderColor }) => $customHeaderColor === true ? `border-radius: 4px 4px 0 0;` : ''};
`

export const HorizontalMenuSubmenuList = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(8, auto);
  max-width: auto;
  min-width: 30rem;
  z-index: 99999;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.colors.mono.white};
  position: absolute;
  padding: 1.5rem;
  border-radius: 0 0.8rem 0.8rem 0.8rem;
  overflow-x: auto;
  gap: 0.2rem;
`

export const HorizontalMenuSubmenuItem = styled.li``

export const HorizontalMenuSubmenuItemLinkLabel = styled.span`
  flex-grow: 1;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  color: ${({ theme }) => theme.colors.orange.default};
  transition: color 0.3s;
  margin-right: 0.5rem;
  ${({ $margin }) => $margin === true ? `margin-left: 2rem;` : ''};
  ${({ $bold }) => $bold === true ? `font-weight: bold;` : ''};
`

export const HorizontalMenuSubmenuItemLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  border-radius: 0.4rem;
  margin-top: 1rem;

  &:hover {
    background: ${({ theme }) => theme.colors.orange.light10};
  };
`

export const HorizontalMenuSubmenuItemLinkQty = styled.span`
  padding: 0.2rem 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.7rem;
  color: #434647;
  background-color: #fff;
  border-radius: 2.5rem;
  margin-left: auto;
  background-color: ${({ theme }) => theme.colors.mono.greyLight};
`

export const SidebarMenuItemLinkIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.mono.greyDark};
`

export const HorizontalMenuVariationList = styled.ul``

export const HorizontalMenuVariationItemLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  border-radius: 0.4rem;
  margin-top: 1rem;

  &:hover {
    background: ${({ theme }) => theme.colors.orange.light10};
  };
`

// ------------------------------------------ MOBILE ------------------------------------------ 

export const HorizontalMenuMobile = styled.aside`
  display: none;
  width: 100%;
  margin-top: 3rem;

  @media ${({ theme }) => theme.screens.lg} {
    display: block;
  }
`

export const HorizontalMenuMobileList = styled.ul`
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-out;
  margin-bottom: 1.5rem;

  ${({ showContent }) => showContent && 'opacity: 1;'}
`

export const HorizontalMenuMobileItem = styled.li`  
  ::marker {
    content: "";
  }
`

export const HorizontalMenuMobileBtn = styled(Button)`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border: 0.15rem solid transparent;
  
  ${({ theme, $changeHeaderColor, isCategoryOpen }) => {
    const backgroundColor = $changeHeaderColor ? theme.colors.mono.white : theme.colors.orange.default;
    const textColor = $changeHeaderColor ? theme.colors.orange.default : theme.colors.mono.white;
    const borderColor = $changeHeaderColor ? theme.colors.orange.default : theme.colors.orange.default;

    return css`
      background: ${backgroundColor};
      color: ${textColor};
      border: ${isCategoryOpen ? `0.15rem solid ${borderColor}` : '0.15rem solid transparent'};
      border-radius: 5px;

      svg {
        fill: ${textColor};
        stroke: ${borderColor};
      }

      &:active, &:hover {
        background: white;
        color: ${borderColor};
        border: 0.15rem solid ${borderColor};

        svg {
          fill: ${borderColor};
        }
      }
    `;
  }}
`;

export const HorizontalMenuMobileCntDrop = styled.div`
  position: absolute;
  z-index: 105;
  width: 100%;
  margin-top: 0.5rem;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.mono.white};
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  max-height: 50vh;
  overflow-y: auto;
  ${({ isCategoryOpen }) =>
    isCategoryOpen &&
    css`
      opacity: 1;
      pointer-events: initial;
    `}
`

export const HorizontalMenuMobileTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.1rem;
  text-align: center;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  padding: 1rem 2rem;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.orange.default};
  background-color: ${({ theme }) => theme.colors.orange.light10};
  border-radius: 4px;

  svg {
    margin: 0;
  }

  @media screen and (min-width: 1200px) {
   width: 100%;
  }
`

export const HorizontalMenuMobileItemLinkLabel = styled(HorizontalMenuItemLinkLabel)`
  color: ${({ theme }) => theme.colors.orange.default};
  text-transform: none;
`

export const HorizontalMenuMobileItemLink = styled(HorizontalMenuItemLink)`
  padding: 1rem 1.5rem;
`

export const SidebarMenuItemLinkQty = styled.span`
  padding: 0.2rem 1rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  /* Mono/Black */
  color: #434647;
  background-color: #fff;
  border-radius: 2.5rem;
`

export const HorizontalMenuMobileSubmenuItemLink = styled(HorizontalMenuSubmenuItemLink)`
  margin: 0;
  margin-left: 1.5rem;
`

export const HorizontalMenuMobileSubmenuItem = styled(HorizontalMenuSubmenuItem)`
 
`

export const HorizontalMenuMobileSubmenuItemLinkLabel = styled(HorizontalMenuSubmenuItemLinkLabel)`
  color: ${({ theme }) => theme.colors.orange.default};
`

export const HorizontalMenuMobileBtnIcon = styled(Icon)`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.mono.greyDark};
`

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin: 1rem;
`

export const HorizontalMenuMobileDivider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.mono.grey};
`

